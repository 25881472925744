.ql-editor {
  min-height: 18em;
}

select {
  padding: 5px;
  border: 1px solid #dddddd;
  border-radius: 5px;
}

.rdt_Table {
  min-height: 300px;
}

.rdt_TableRow:last-child {
  border-bottom: 1px solid #dddddd;
}

.tab-list {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 20px;
  margin: 0;
  list-style: none;
}

.tab-list li {
  padding: 8px;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
  background-color: #f5f5f5;
}

.tab-list li.active {
  background-color: #696cff;
  color: #fff;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

colgroup {
  border: 1px solid #a6a7ff;
}

.form-label {
  font-size: 0.7rem !important;
}

.close-modal {
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #696cff;
  color: #fff;
  border-radius: 10px;
  padding: 5px 12px;
  box-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.2);
}

.modal-dialog {
  max-width: 80%;
  margin: 1.75rem auto;
}

.filter-icon {
  cursor: pointer;
  margin-right: 0.3rem;
}
.filter-label {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 0.2rem;
}

.filter-overflow {
  height: 300px;
  overflow-y: scroll;
}

.expanded-order-row {
  /* background-color: #f5f5f5; */
  margin: 10px 5px;
}

.expanded-order-row h2 {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.2rem;
  margin-left: 0.5rem;
}
